<template>
  <div class="w-full p-1 justify-between flex bg-[#F8F8F8]">
    <div>
      <div class="font-bold capitalize text-[16px]">
        Komchat {{ dataSubscribe.subscription_plan_category }}
      </div>
      <div class="flex gap-2 items-center">
        <div>
          <b-icon
            class="mr-[5px] text-lg"
            icon="arrow-return-right"
          />Notif Pengiriman
        </div>
        <div>
          <b-button
            v-if="badge === 'Regular' ? dataSubscribe.whatsapp_expired_at !== 0 : currentPackage === 'basic' && dataSubscribe.whatsapp_expired_at !== 0 "
            variant="outline-primary"
            size="sm"
            @click="handleClick('extends')"
          >
            Perpanjang</b-button>
        </div>
      </div>
      <div class="flex gap-1 ml-[1.3rem] items-center">
        <span
          :class="dataSubscribe.whatsapp_expired_at !== 0? 'bg-[#34A770]' : 'bg-primary'"
          class="h-2 rounded-lg block w-2"
        /> <span v-if="badge !== 'Regular' && currentPackage !== 'basic'">Unlimited</span> <span v-else-if="dataSubscribe.whatsapp_expired_at !== 0">Expired : {{ dataSubscribe.whatsapp_expired_at }} Hari Lagi </span> <span v-else>Fitur Telah Expired</span>
      </div>
    </div>
    <div class="">
      <b-button
        v-if="dataSubscribe.whatsapp_expired_at === 0? true : dataSubscribe.whatsapp_expired_at !== 0 && dataSubscribe.subscription_plan_category !== 'basic'"
        variant="primary"
        size="sm"
        @click="handleClick(dataSubscribe.whatsapp_expired_at !== 0 && dataSubscribe.subscription_plan_category?'upgrade': 'subscrabe')"
      >
        <span v-if="dataSubscribe.whatsapp_expired_at !== 0 && dataSubscribe.subscription_plan_category === 'starter' ">Upgrade Package</span>
        <span v-if="dataSubscribe.whatsapp_expired_at === 0">Aktifkan</span>
      </b-button>
    </div>
    <extendSubscribeModal :curent-package="currentPackage" />
    <komchatPricingModal
      :re-activate="isReactivate"
      :is-upgrade="isUpgrade"
    />
  </div>
</template>
<script>
import extendSubscribeModal from '../opsional/modal/extend-subscribe-modal.vue'
import komchatPricingModal from '../opsional/modal/komchat-pricing-modal.vue'

export default {
  components: {
    extendSubscribeModal,
    komchatPricingModal,
  },
  props: {
    dataSubscribe: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      currentPackage: this.dataSubscribe.subscription_plan_category,
      isUpgrade: false,
      isReactivate: false,
      badge: this.$store.state.whatsapp.check_wa.is_user_type,
    }
  },
  methods: {
    handleClick(value) {
      if (value === 'extends') {
        this.$bvModal.show('modal-extends-subcription')
      } else if (value === 'upgrade') {
        this.isUpgrade = true
        this.$bvModal.show('modal-choose-wa')
      } else if (value === 'subscrabe') {
        this.isReactivate = true
        this.$bvModal.show('modal-choose-wa')
      }
    },
  },
}
</script>
